<template lang="pug">
v-container(fluid)
	v-row.row--sticky.mb-4
		v-col.pr-0(cols='7')
			v-text-field(:value='filterSearch', dense, filled, clearable, :label='$t("form.search")', @click:clear='filterSearch=""', v-debounce:500ms.fireonempty='newVal => filterSearch=newVal')
		v-col.text-center(cols='5')
			v-btn.pa-2.mt-2.white--text(color='primary', outlined, :disabled='disablePublish', @click='publishall()')
				v-icon.pr-2 mdi-check-all
				| {{ $t("button.publishall") }}
		v-col.pa-0
			.treeview__header
				.treeview__action
					v-tooltip(v-if='false' top, open-delay='300', color='white')
						template(v-slot:activator='{ on, attrs }')
							v-btn.pa-1(v-bind='attrs', v-on='on', plain, text, @click='renewAll()')
								v-icon(small, color='primary') mdi-backup-restore
						span.grey--text.text--darken-3
							| {{ $t("label.renewAll") }}
				.treeview__name
					| {{ $t("label.zoneName") }}
				.treeview__bulletin
					| {{ $tc("label.bulletin", 1) }}
				.treeview__evenement
					| {{ $tc("label.evenement", 2) }}

	v-row
		template(v-if='loading')
			v-col
				v-skeleton-loader(type='list-item', v-for='i in 60', :key='i', loading, transition='scroll-y-transition')

		template(v-else)
			draggable.tree-list--sentinelle.pa-0(ref='tree-list', :list='castzonesOrder', tag='ul', v-bind='dragOptions', @start='onDragStart', @end='onDragEnd', :disabled='!dragIsActive || filterSearch', :data-drag='`${!dragIsActive || filterSearch ? "false" : "true"}`')
				transition-group(type='transition', :name='!drag ? "flip-list" : null')
					tree-item(:item='item', v-for='item in filteredList', :key='item.id', :class='{ "is-selected": currentZoneId == item.id }', :openAll='true')
						template(v-slot:label='slotProps')
							.tree-list__template(@click.prevent='clickZone(slotProps.item)')
								//- RAS accès rapide
								v-tooltip(top, open-delay='300', color='white')
									template(v-slot:activator='{ on, attrs }')
										v-btn.castzone__ras(v-bind='attrs', v-on='on', text, x-small, plain, @click.Stop='createDraftRasOnZone(slotProps.item.id)', :disabled='hasDraft(slotProps.item)')
											v-icon(small) mdi-thumb-up
									span.grey--text.text--darken-3
										| {{ $t("label.createDraftRAS") }}

								//- Renouveler les bulletins
								v-tooltip(top, open-delay='300', color='white')
									template(v-slot:activator='{ on, attrs }')
										v-btn.castzone__renew(v-bind='attrs', v-on='on', text, x-small, plain, @click.Stop='renewZone(slotProps.item.id)', :disabled="!!doing", :loading="doing=='renew_zone_'+ slotProps.item.id")
											v-icon(small) mdi-backup-restore
									span.grey--text.text--darken-3(v-html='bulletinsList(slotProps.item)')

								//- name + map locate
								a.castzone__name
									//- Alertes
									v-tooltip(v-if='hasWeventSuggestAlert(slotProps.item)', top, open-delay='600')
										template(v-slot:activator='{ on, attrs }')
											v-icon.castzone__alert(v-bind='attrs', v-on='on', small, :color='gWeventColorSeverity(3)') mdi-alert
										| {{ $t("label.hasAlertSuggests") }}
									| {{ slotProps.item.name }}

								//- channels
								span.castzone__channel
									channel-icon(:zone='slotProps.item', :channel='channel', v-for='channel in Object.keys(slotProps.item.bulletins).sort().reverse()', :key='channel')

								//- WEvent icones
								wevent-icon-list.castzone__wevent(:zone='slotProps.item', groupIcon)

								//- Actions (graph + édition)
								v-btn.castzone__forecasts(@click.stop='gForecastCastzone(slotProps.item.id)', x-small, color='lightgrey')
									v-icon(x-small, color='primary') mdi-chart-areaspline
								v-btn.castzone__edit(@click.stop='clickZone(slotProps.item, true)', x-small, color='primary')
									v-icon(x-small) mdi-pencil
	confirm-dialog-renew(ref="confirmDialogRenew")
</template>

<script>
	import { mapActions, mapState, mapGetters } from 'vuex'
	import moment from 'moment'
	import api from '@/api'
	import { scrollParentToChild } from '@/utils'
	moment.locale('fr')

	export default {
		components: {
			Draggable: () => import('vuedraggable'),
			treeItem: () => import('./TreeItem'),
			ChannelIcon: () => import('@/components/Castzones/ChannelIcon'),
			WeventIconList: () => import('@/components/Castzones/WEventIconList'),
			ConfirmDialogRenew: () => import('@/components/ConfirmDialogRenew')
		},
		data() {
			return {
				moment: moment,
				dragIsActive: true,
				drag: false,
				loading: true,
				dragTimeoutId: null,
				dragTimeoutTime: 3000,
				doing: null
			}
		},
		computed: {
			...mapState(['castzones', 'castzonesOrder', 'castpoints', 'bulletinsDraft']),
			...mapGetters(['currentDraftIds']),

			dragOptions() {
				return {
					fallbackTolerance: 6,
					scrollSensitivity: 200,
					forceFallback: true,
					animation: 200,
					disabled: false,
					ghostClass: 'ghost',
				}
			},

			filteredList() {
				if (this.filterSearch) return this.castzonesOrder.filter(cz => cz.searchIndex !== null && cz.searchIndex.toLowerCase().includes(this.filterSearch.toLowerCase()))
				return this.castzonesOrder
			},

			filterSearch: {
				get() {
					return this.$store.state.filter_search
				},
				set(value) {
					this.gSetKey('filter_search', value)
				},
			},

			disablePublish() {
				return (!this.currentDraftIds || this.currentDraftIds.length == 0) && !this.weventsNeedPublish
			},

			weventsNeedPublish() {
				let result = false
				for (let z = 0; z < this.castzones.length; z++) {
					this.castzones[z].wevents.map(w => {
						if (w.will_be_active != null) result = true
					})
				}
				return result
			},

			bulletinsList() {
				return function(zone) {
					let string = `<b class="grey--text text--darken-2 subtitle-2">${this.$t('button.renew')}</b><div class="top--divider"></div>`

					let bulletins = []

					for (let channel in zone.bulletinsDraft) {
						const bulletin = this.bulletins && zone.bulletins[channel] && this.bulletins[zone.bulletins[channel]]

						if (bulletin) {
							let bulletinText = ''
							let time = bulletin.time_publish
							let publishTimed = time > this.utcTime ? this.$t('label.justnow') : this.$moment.duration(time - this.utcTime, 'seconds').humanize(true)

							bulletinText = this.bulletins[zone.bulletins[channel]].txt.replaceAll('\n', '<br/>')

							if (zone.bulletinsDraft[channel]) {
								publishTimed = `${this.$t('ListSearch.renewDraftAlreadyExist')}`
								bulletinText = ''
							}

							bulletins.push(`<div class="mb-2"><div><span class="bulletin-list__channel">${this.$te('channel_list.' + channel) ? this.$t('channel_list.' + channel) : channel}</span> <span class="bulletin-list__date">${publishTimed}</span></div> <div class="ml-3 bulletin__text">${bulletinText}</div> </div>`)
						}
					}

					string += bulletins.join('')

					return string
				}
			},
		},
		watch: {
			currentZoneId: function(id) {
				if (!id || id == -1 || !this.$refs['tree-list']) return
				const treeList = this.$refs['tree-list'].$el.closest('.v-navigation-drawer__content')
				const childCible = treeList.querySelector('#tree-item-' + id)
				const headerBound = treeList.querySelector('.row--sticky').getBoundingClientRect()
				if (treeList && childCible) scrollParentToChild(treeList, childCible, headerBound.height * -1)
			},
		},
		async mounted() {
			await this.stateReady.promise
			this.loading=false
		},
		methods: {
			...mapActions(['setStateDraggingResolve', 'createStateDragging']),
			...mapActions(['publishBulletinDraft', 'publishAllDraft', 'createDraftRasOnZone', 'createDraftRenewOnZone', 'publishWeventAll', 'createBulletinDraft']),

			onDragStart() {
				// bloquer l'app avec un mode "busy/pending" dans le store
				this.createStateDragging()
				this.drag = true

				if (this.dragTimeoutId) clearTimeout(this.dragTimeoutId)
			},

			onDragEnd() {
				this.drag = false

				if (this.dragTimeoutId) clearTimeout(this.dragTimeoutId)
				this.dragTimeoutId = setTimeout(() => {
					this.saveSort()
				}, this.dragTimeoutTime)
			},

			async saveSort() {
				this.gLoading("Sauvegarde de l'ordre des zones ...")
				try {
					await api.user.update({
						castzones_seq: this.castzonesOrder.map(cz => cz.id),
					})
				} catch (e) {
					console.log(e)
				} finally {
					this.gLoadingEnd()
				}

				// débloquer l'app du mode busy pour relancer les appels api comme GET ƒ
				this.setStateDraggingResolve()
			},

			undoSort() {
				this.castzonesOrder = this.castzones.sort((a, b) => a.seq - b.seq)
			},

			clickZone(zone, open = false) {
				this.gLoadZone({ id: zone.id, show: open, route: open })
				this.gSetLastAriane(zone)
			},

			async renewZone(zoneId) {
				this.doing = 'renew_zone_' + zoneId
				await this.createDraftRenewOnZone(zoneId)
				this.doing = null
			},

			async publishall() {
				if (this.currentDraftIds.length > 0 && (await this.gGetConfirm(null, this.$tc('confirmDialog.confirmBulletinPublishAll', this.currentDraftIds.length, { count: this.currentDraftIds.length })))) {
					this.gLoading('Publication des bulletins')
					try {
						await this.publishAllDraft()
					} finally {
						this.gLoadingEnd()
					}
				}

				if (this.weventsNeedPublish && (await this.gGetConfirm(null, this.$t('confirmDialog.confirmWeventPublishAll')))) {
					this.gLoading('Publication des évènements')
					try {
						await this.publishWeventAll()
						this.$emit('refresh')
					} finally {
						this.gLoadingEnd()
					}
				}
			},

			async renewAll() {
				if (this.$refs['confirmDialogRenew']) {
					const channels = await this.$refs['confirmDialogRenew'].confirm('titre','renew all')
					
					if(channels) {
						this.gLoading( this.$t('loading.renewAll') )
						try {
							for(const channel of channels) {
								const channelName = this.$te(`channel_list.${channel}`) ? this.$t(`channel_list.${channel}`) : channel
								const zones = []
								for(const zone of this.castzones) {
									if(	zone.bulletins && channel in zone.bulletins && zone.bulletins[channel]
										&& this.bulletins[zone.bulletins[channel]] 
										&& this.bulletins[zone.bulletins[channel]].id_castzone == zone.id
										&& !this.bulletins[zone.bulletins[channel]].draft
										&& zone.bulletinsDraft && channel in zone.bulletinsDraft && zone.bulletinsDraft[channel] === null
									) {
										zones.push(zone)
									}
								}

								if(zones.length === 0) {
									this.gLoadingMsg(this.$t('loading.renewAllNone', {channel: channelName}))
									await this.gSleep(1500)
									continue
								}

								let cur=0
								for(const zone of zones) {
									cur++
									this.gLoadingMsg(this.$t('loading.renewAllProgress', {channel: channelName, count: cur, total: zones.length}))
									const bulletin = this.bulletins[zone.bulletins[channel]]
									await this.createBulletinDraft({channel, id_castzone: zone.id, txt:bulletin.txt})									
								}
							}
						} finally {
							await this.gSleep(1000)
							this.gLoadingEnd()
						}
					}

				}
			},

			hasDraft(zone) {
				if (!zone) return false
				for (let channel in zone.bulletinsDraft) {
					const draft = this.gGetDraftById(zone.bulletinsDraft[channel])
					if (draft && draft.id_castzone == zone.id) return true
				}
				return false
			},

			hasWeventSuggestAlert(zone) {
				if (!zone || !zone.castpoints) return false
				for (let cpId of zone.castpoints) {
					let cp = this.castpoints[cpId]
					if (!cp || !cp.weventSuggests) continue
					for (let ws of cp.weventSuggests) if (this.weventKind(ws.id_kind).is_alert && ws.severity >= 3) return true
				}
				return false
			},
		},
	}
</script>

<style lang="scss">
	@mixin tree-list--grid-system {
		display: grid;
		// ras alert name channels wevent btn btn
		grid-template-columns: repeat(2, 32px) auto calc(32px * 4) calc(40px * 2) 44px 44px;
		grid-auto-rows: 40px;
		align-items: center;
		justify-items: start;
	}

	.row--sticky {
		background: white;
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 10;
	}

	.treeview__header {
		font-size: 0.9rem;
		font-weight: 500;
		color: #757575;

		@include tree-list--grid-system;
	}
	.treeview__action {
		grid-column-start: 1;
	}
	.treeview__name {
		grid-column-start: 3;
	}
	.treeview__bulletin {
		grid-column-start: 4;
	}
	.treeview__evenement {
		grid-column-start: 5;
	}

	.bulletin-list__channel {
		text-transform: uppercase;
		font-size: 0.7em;
		font-weight: 600;
	}

	.bulletin-list__date {
		font-size: 0.7em;
	}

	.bulletin__text {
		font-size: 0.8em;
	}

	.tree-list__template {
		@include tree-list--grid-system;
		cursor: pointer;

		.v-btn:not(.v-btn--round).v-size--x-small {
			padding: 0;
		}
		.castzone__ras {
			grid-column-start: 1;
		}
		.castzone__renew {
			grid-column-start: 2;
		}
		.castzone__name {
			grid-column-start: 3;
			width: 100%;
			font-size: 0.87rem;
			padding: 0.25rem;
			margin-top: 0.25rem;
			color: #212121;
			user-select: none;
			display: flex;
			align-items: baseline;
		}
		.castzone__channel {
			display: flex;
			flex-wrap: wrap;
			gap: 0.25rem;
		}
		.castzone__wevent {
			grid-column-start: 5;
			min-width: 100%;

			> .row > div {
				margin-left: -5px;
			}
		}
		.castzone__forecasts {
			grid-column-start: 6;
		}
		.castzone__edit {
			grid-column-start: 7;
		}
	}

	.top--divider {
		border-top: solid 1px #e0e0e0;
		margin-top: 0.75rem;
		padding-top: 0.75rem;
	}

	// DRAG N DROP ANIMATION
	.flip-list-move {
		transition: transform 0.5s;
	}
	.no-move {
		transition: transform 0s;
	}
	.ghost {
		opacity: 0.5;
		background: #c8ebfb;
	}
	.tree-list--sentinelle {
		min-height: 20px;
		min-width: 100%;
		overflow-y: auto;
	}
</style>
